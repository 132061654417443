export const STRINGS = {
  ENGLISH: {
    LOREM_IPSUM:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    WELCOME_BACK: 'Welcome Back !',
    WELCOME: 'Welcome',
    BACK: 'Back!',
    COMPANY_INFO: '© 2023 - Panorbit, Inc. | Privacy',
    INVALID_OTP: 'OTP is incorrect',
    PROCEED: 'Proceed',
    SIGN_IN: 'Log In',
    BACK_TO_LOGIN: 'Back to login',
    REQUIRED: ' field is required',
    ALL_FIELDS_REQUIRED: 'All fields required',
    E3008: 'Invalid format',
    MAIL: 'Mail',
    IN_CORRECT_EMAIL: 'Email Id is not correct',
    IN_CORRECT_OTP: 'OTP is not correct',
    MAIL_PLACEHOLDER: 'Enter Email Address',
    OTP: 'Enter One Time Password',
    OTP_FULL_FORM: 'One Time Password (OTP)',
    DASHBOARD: 'Dashboard',
    SAVE: 'Save',
    SAVED: 'Saved',
    SAVE_CHANGES: 'Save Changes',
    COMPANY: 'Company',
    PHONE_NO: 'Phone Number',
    EDIT: 'Edit',
    EDIT_DETAILS: 'Edit Details',
    SUCCESS: 'Success',
    SUCCESSFULLY: 'Successfully',
    DEFAULT_ERR: 'An Error Occured',
    DEFAULT_ERR_400: 'Error Occured, please check and try again error',
    DEFAULT_ERR_500: 'Error Occured, please check and try again error',
    INTERNET_ERR: 'Internet Connection Error',
    ADD: 'Add',
    CREATE_BRAND: 'Create Brand',
    ADD_NEW: 'Add New',
    NEXT: 'Next',
    CREATE: 'Create',
    COMPANY_NAME: 'Client Name',
    TYPE: 'Type',
    ORGANISATION: 'Organisation',
    WEBSITE: 'Website',
    WEBSITE_NAME: 'Website Name',
    ADD_WEBSITE: 'Add Website',
    WEBSITE_URL: 'Website URL',
    ENTER_WEBSITE_URL: 'Enter website URL',
    NO_WEBSITES_ADDED: 'No Websites Added',
    CREATE_NEW_WEBSITE_VERSION: 'Create New Website Version?',
    SEARCH: 'Search',
    SEARCH_CLIENTS: 'Search Clients',
    SEARCH_USERS: 'Search Users By Name',
    MY_AGENCY: 'my agency',
    LOGIN: 'Log In',
    LOGIN_TO_ACCOUNT: 'Please Login to Your Account',
    LOGOUT: 'logout',
    NICE_TO_SEE_YOU: 'Nice To See You Again',
    SUCCESSFULL_DELETION: 'Brand deleted successfully',
    BRAND_ADDED: 'Brand added successfully',
    DELETE_CONFORMATION: 'Are you sure you want to delete ?',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    DELETE: 'Delete',
    COMPANY_LABEL: 'Client Name ',
    FIRST_NAME: 'First Name ',
    NAME: 'Name ',
    LAST_NAME: 'Last Name ',
    EMAIL: 'Email ',
    EMAIL_ADDRESS: 'Email Address',
    PHONE_NUMBER: 'Phone Number ',
    USER_UPDATED: 'User profile updated successfully',
    PROJECT_UPDATED: 'Project updated successfully',
    USER_ADDED: 'User added successfully',
    USERS: 'Users',
    USER_DELETED: 'User deleted successfully',
    NOT_FOUND_CLIENTS_LIST: 'No clients found in list',
    WANT_TO_ADD_CLIENT: 'Want to add new client?',
    ADD_FILE: 'Add file',
    NOT_FOUND_USER_LIST: 'No user found in list',
    WANT_TO_ADD_USER: 'Want to add new user?',
    ADD_USER: '+ Add User',
    ADD_NEW_USER: 'Add New User',
    UPDATE_USER: 'Update User',
    ENTER_USER_DETAILS: 'Enter User Details',
    USER_TYPE: 'User Type',
    USER_ROLE: 'User Role',
    PRIMARY_CONTACT_NAME: 'Contact Person Name',
    PRIMARY_CONTACT_NUMBER: 'Contact Person Number',
    ADD_CLIENT: '+ Add Client',
    FOR: 'for',
    SEARCH_PROJECTS: 'Search Projects',
    PROJECTS: 'Projects',
    PROJECT_NAME: 'Project Name',
    PROJECT_DESCRIPTION: 'Project description :',
    PROJECT_TYPE: 'Project Type',
    START_DATE: 'Start Date',
    STARTED_ON: 'Started On',
    END_DATE: 'End Date',
    AGENCY_NAME: 'Agency Name :',
    AGENCY_SPOC: 'Agency’s SPOC',
    AGENCY_TEAM_MEMBERS: 'Agency team members',
    AGENCY_PROJECT_LEAD: 'Agency project lead',
    SELECT_SPOC: 'Select SPOC',
    CLENT_SPOC: "Client's SPOC",
    AGENCY_SPOC_EMAIL: 'Agency’s SPOC Email :',
    CLENT_SPOC_EMAIL: "Client's SPOC Email :",
    CLIENT_TEAM_MEMBERS: 'Client team members',
    SELECT_STATUS: 'Select Status',
    PROJECT_ADDED: 'Project added successfully',
    PROJECT_DELETED: 'Project deleted successfully',
    FOLDER_CREATED: 'Folder created successfully',
    FOLDER_UPLOADED: 'Folder uploaded successfully',
    FILE_UPLOADED: 'Files uploaded successfully',
    FILE_DELETED: 'File deleted successfully',
    LINK_ADDED: 'Link added successfully',
    LINK_DELETED: 'Link deleted successfully',
    WEBPAGE_DELETED: 'Web page deleted successfully',
    USER_LIST: 'Users :',
    CLIENT_TYPE: 'Client Type',
    SOCIAL_MEDIA_LINK: 'Social Media Link',
    CLIENT_NAME: 'Client Name',
    STATUS: 'Status',
    NOT_FOUND_PROJECT: 'No project found in list',
    NOT_FOUND_ASSET: 'No asset found in list',
    NOT_FOUND_PAGES: 'No web page found in list',
    WANT_TO_ADD_PROJECT: 'Want to add new project?',
    NOT_FOUND_BRAND: 'No Brand found in list',
    NOT_FOUND_FOLDER: 'No Folder or file found in list',
    NOT_RECORD_FOUND: 'No Record Found',
    WANT_TO_ADD_FOLDER: 'Want to add new Folder or file?',
    ADD_PROJECT: '+ Add project',
    ADD_POST: 'Add Post',
    CREATE_NEW_POST: 'Create new post',
    ENTER_POST_DETAILS: 'Enter post details',
    EDIT_POST: 'Edit Post',
    POST_TITLE: 'Post Title :',
    POST_TITLE_PLACEHOLDER: 'Enter Post Title',
    POST_START: 'Post Start Date ',
    POST_START_PLACEHOLDER: 'Enter Post Start Date',
    ALL_DAY: 'All Day Post',
    POST_DESCRIPTION: 'Post Description :',
    POST_DESCRIPTION_PLACEHOLDER: 'Enter Post Description',
    CALENDAR: 'Calendar',
    DETAILS: 'Details',
    EVENT_DETAILS: 'Event Details',
    EVENT_TITLE: 'Event Title',
    EVENT_DESCRIPTION: 'Description',
    EVENT_STATUS: 'Status',
    EVENT_POST_DATE: 'Start Date',
    EVENT_ADD_SUCCESS: 'Event Added Successfully',
    ASSET_TITLE: 'Asset Title',
    ASSET_DESCRIPTION: 'Asset Description',
    ASSET_CAPTION: 'Asset Caption',
    ASSET_STATUS: 'Asset Status',
    UPLOAD: 'Upload',
    UPDATE: 'Update',
    UPLOAD_ASSET: 'Upload Asset',
    ASSET_DETAILS: 'Asset Details',
    EVENT_UPDATED: 'Event Updated Succesfully',
    ASSET_ADDED: 'Asset Added Successfully',
    ASSET_UPDATED: 'Asset Updated Successfully',
    ASET_VERSION_CREATED: 'Ásset Version Created Successfully',
    NO_ASSETS: 'No Assets Created',
    WANT_TO_ADD_ASSETS: 'Want to add Assets?',
    EDIT_CAPTION: 'Edit Caption',
    SAVE_NEW_CAPTION: 'Save New Caption',
    UPLOAD_NEW_VERSION: '+ New Version',
    ADD_VERSION: '+ Add Version',
    SHARE: 'Share',
    VIEW_CAPTION_HISTORY: 'View Caption History',
    VIEW_HISTORY: 'View History',
    CAPTION: 'Caption',
    COMMENT_ADDED: 'Comment Added Successfully',
    COMMENT_DELETED: 'Comment Deleted Successfully',
    ADD_A_COMMENT: 'Add a Comment',
    SAVE_COMMENT: 'Save Comment',
    DELETE_COMMENT: 'Delete Comment',
    POST: 'Post',
    POST_REPLY: 'Post Reply',
    POST_COMMENT: 'Post Comment',
    REPLY: 'Reply',
    ASSET_FULL_SCREEN: 'Asset Full Screen',
    CHANGE_STATUS: 'Are you sure you want to Change Status to ',
    COPY_URL: 'Url copied successfully',
    YES: 'Yes',
    NO: 'No',
    COMMENT: 'Comment',
    COMMENTS: 'Comments',
    CONTACT_AGENCY: 'Contact Agency',
    LINK_COPY_SUCCESS: 'Link Copied Successfully',
    NO_COMMENTS_FOR_VERSION: 'No Comments',
    CAPTION_EMPTY: 'Caption cant be empty',
    CAPTION_HISTORY: 'Caption History',
    DOWNLOAD_ASSET_ERROR: 'Unable to download asset',
    NO_CAPTIONS: 'No Captions for this asset',
    INVALID_FILE_TYPE: 'Invalid File Type',
    ADD_MORE: '+ Add More',
    REFRESH: 'Refresh',
    VIDEO_WATERMARK_IN_PROGRESS: 'Video Watermarking in progress',
    FOLDER_NAME: 'Folder name :',
    ENTER_WEBSITE_LINK: 'Enter Website Link',
    ENTER_WEBSITE_NAME: 'Enter Website Name',
    WEBSITE_ANNOTATION: 'Website Annotation',
    SELECT_SOCIAL_MEDIA: 'Select social media',
    ENTER_SOCIAL_MEDIA_LINK: 'Enter social media link',
    PROFILE: 'Profile',
    SETTINGS: 'Settings',
    HELP: 'Help',
    BRANDS: 'Brands',
    CREATE_NEW: '+ Create New',
    CREATE_NEW_TEXT: 'Create New',
    NEW: 'New',
    ENTER_BRAND_NANE: 'Enter Brand Name',
    SELECT_ACC_MAN: 'Select Account Manager',
    ENTER_DESCRIPTION: 'Enter Description',
    DRAG_N_DROP_FILE: 'Drag and drop or',
    BROWSE_FILE: 'Browse File',
    FILE_SUPPORTED_FORMATS: 'Supported format - pdf, jpg, mp4',
    IMAGE_SUPPORTED_FORMATS: 'Supported format - jpg, png',
    FILE_UPLOAD_TEXT: 'Only files can be uploaded here',
    BRAND_NAME: 'Brand Name',
    ACCOUNT_MANAGER: 'Account Manager',
    DESCRIPTION: 'Description',
    BRAND_LOGO: 'Brand Logo',
    CREATE_NEW_BRAND: 'Create a new brand',
    EDIT_BRAND: 'Edit brand details',
    ENTER_BRAND_DETAILS: 'Update brand details',
    CREATE_NEW_PROJECT: 'Create a new project',
    UPDATE_NEW_PROJECT: 'Update a project',
    CHOOSE_PROJECT_TYPE: 'Choose a project type to start',
    ENTER_PROJECT_DETAILS: 'Enter project details',
    ENTER_PROJECT_NAME: 'Enter project name',
    POST_NAME: 'Post name',
    ENTER_POST_NAME: 'Enter post name',
    TODAYS_POST: 'Todays posts',
    NOTIFICATION_TEXT:
      "Akshay Kumar Changed status from 'In Review' to 'Completed'",
    NOTIFICATIONS: 'Notifications',
    NO_NOTIFICATIONS: 'You have zero notifications',
    VIEW_ALL: 'View All',
    SELECT_PROJECT_LEAD: 'Select Project Lead',
    ADD_NEW_ENTRY: 'Add new entry',
    UPDATE_ENTRY: 'Update entry',
    ENTER_DETAILS: 'Enter details',
    TITLE: 'Title',
    LINK: 'Link',
    ENTER_TITLE: 'Enter Title',
    ENTER_LINK: 'Enter Link',
    CREATE_FOLDER: 'Create Folder',
    UPLOAD_FOLDER: 'Upload folder',
    UPLOAD_FILES: 'Upload file',
    UPLOAD_NEW_FOLDER: 'Upload new folder',
    UPLOAD_THE_FOLDER: 'Upload the folder',
    UPLOAD_THE_FILE: 'Upload the file',
    UPLOAD_NEW_FILE: 'Upload new files',
    UPDATE_CAPTION: 'Update Caption',
    DELETE_FILE: 'Delete File/Folder',
    DELETE_FILE_CONFIRMATION: 'You are about to deleteing this file/folder',
    DETAIL: `Detail's`,
    DATE: `Date`,
    UPDATE_DETAILS: `Update Details`,
    NO_POST_TODAY: `No Post for Today`,
    DELETE_USER: `Delete User?`,
    DELETE_USER_CONFIRMATION: `Are you sure you want to delete this`,
  },
};

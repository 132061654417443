import styled from 'styled-components';
import { COLORS } from '../../constants/BaseStyles';
import { device } from '../../constants/device';
import LOGIN_BG from '../../assets/images/Login_Background.png';

const AuthBGImageContainer = styled.div`
  background: no-repeat url(${LOGIN_BG});
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-size: cover;
`;

const AuthLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 100vh;

  @media ${device.tablet} {
    display: none;
  }
`;

const AuthImageContainer = styled.div`
  margin: 0 50px 0 50px;
  height: 100%;
`;

const AuthLeftImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const AuthRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33vw;
  height: 100vh;
  @media ${device.tablet} {
    width: 100vw;
  }
`;

const Logo = styled.img`
  width: 250px;
  height: 75px;
  object-fit: contain;
`;

const AuthFormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 530px;
  background: ${(props) =>
    props.noBg
      ? 'transparent'
      : COLORS.PRIMARY_GREY}; /* Conditional background color */
  border-radius: 16px;

  @media ${device.tablet} {
    justify-content: space-evenly;
    height: calc(100vh - 100px);
  }
`;

const CompanyInfo = styled.p`
  position: absolute;
  bottom: 25px;
  font-size: 0.6rem;
  color: ${COLORS.TEXT_MEDIUM_GREY};
`;

export {
  AuthBGImageContainer,
  AuthLeftContainer,
  AuthImageContainer,
  AuthLeftImage,
  AuthRightContainer,
  Logo,
  AuthFormContainer,
  CompanyInfo,
};
